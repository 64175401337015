<template>
    <div class="container">
        <div class="row justify-content-center">
        <div class="col-xl-8">
            <div class="about-intro text-center">
            <span v-html="data.slogan"></span>
            <!-- <h1><span class="brand-color">Hello,</span> <br> my name is <br> {{data.name}}</h1> -->
            <div v-html="description"></div>
            <ul class="abt-social-icon">
                <li>
                    <a :href="data.facebook" target="_blank" v-b-tooltip.hover title="Facebook"> <i class="fab fa-facebook-f"></i> </a>
                </li>
                <li>
                    <a :href="data.instagram" target="_blank" v-b-tooltip.hover title="Instagram"> <i class="fab fa-instagram"></i> </a>
                </li>
                <li>
                    <a :href="data.youtube" target="_blank" v-b-tooltip.hover title="Youtube"> <i class="fab fa-youtube"></i> </a>
                </li>
            </ul>
            </div>
        </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import api from "@/services/api";   
export default{
    name: 'AboutIntro',

    data() {
        return {
            data: {},
            description: "",
        }
    },
    mounted() {
        this.fetchAboutIntro();
    },
    methods: {
        async fetchAboutIntro() {
            try {
                const response = await axios.get(api.getUrl('/home/banner'));
                let result = response.data;
                if (result.success) {
                    this.data = result.data.data; 
                    this.description = result.data.description;
                }
            } catch (error) {
                console.error("Error fetching AboutIntro data:", error);
            }
        }
    }
}
</script>