<template>
    <div class="container">
        <div class="row align-items-center justify-content-around">
            <div class="col-xl-4 col-lg-4 col-md-6 mb-3">
            <div class="specialization-wrap">
                <span>My specialization</span>
                <h2>If you’ve seen my work, now <span class="brand-color">see the details</span>  that make it.</h2>
                <div v-html="data.detail"></div>
                <span class="section-border"></span>
            </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 mb-3">
            <div class="introVideo">
                <img :src="data.image" class="img-fluid" >
                <div class="play-btn" @click="playVideo" v-b-modal.video-player-modal>
                <span class="icon-play"></span>
                </div>
                <div class="work-exp">
                <h3>{{ data.experienceYear }}</h3>
                <span>
                    years <br> experience <br> working
                </span>
                </div>
            </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import api from "@/services/api";

export default{
    name: 'Specialize',
    props:{
        specializeInfo:{
            required:true,
            type:[Object],
        }
    },
    data() {
        return {
            data: this.specializeInfo,
            video_url: "",
        }
    },
    methods: {
        async playVideo() {
            let app = this;
            // const response = await axios.get(api.getUrl('/profile/video/'+app.data.id), {
            //     responseType: 'blob',
            //     headers: {
            //         'Content-Type': 'application/json',
            //         'Range': 'bytes=0-999999'
            //     },
            // });
            // const blob = new Blob([response.data], { type: 'video/mp4' });
            // app.$root.$emit("request-video", URL.createObjectURL(blob));
            const response = await axios.get(api.getUrl('/profile/video/'+app.data.id));
            app.$root.$emit("request-video", response.data.url);
        },
    },
    watch: {
        specializeInfo(value) {
            let app = this;
            app.data = value;
        },
    },
}
</script>