<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h2>Work Portfolio</h2>
                <p>Creative projects I've contributed to</p>
            </div>
            <div class="col-12">
                <template v-if="data.length > 0">
                <carousel :autoplay="false" :nav="false" :items="4" :margin="20" :dots="true"
                    :responsive="{
                        0:{items:1},
                        600:{items:2},
                        992:{items:2},
                        1200:{items:4}
                    }">
                    <div class="projectGrid" v-for="(item,i) in data" :key="i" @click="index = i" :style="bgImage(item.icon)" >
                        <div class="projectTitle">
                            <h3>{{item.title}}</h3>
                            <span>{{item.category}}</span>
                        </div>
                    </div>
                </carousel>
                </template> 
                <CoolLightBox :items="items" :index="index" @close="index = null"> </CoolLightBox>
            </div>
        </div>
    </div>
</template>
<script>
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import carousel from 'vue-owl-carousel'
export default{
    name: 'Projects',
    components:{
        carousel,
        CoolLightBox
    },
    props:{
        projectsInfo:{
            required:true,
            type:[Array,Object],
        }
    },
    data() {
        return {
            data: this.projectsInfo,
            items: [],
            index: null
        }
    },
    methods:{
        bgImage(image){
            return `background-image: url(${image})`;
        },
    },
    watch: {
        projectsInfo(value) {
            let app = this;
            app.data = value;
            value.map(function(data) {
                let url = data.icon;
                if(data.url){
                    url = data.url;
                }
                const item = {
                    title: data.title,
                    description: data.description,
                    src: url,
                };
                app.items.push(item);
            });
        },
    },
}
</script>