var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-12"},[(_vm.data.length > 0)?[_c('carousel',{attrs:{"autoplay":false,"nav":false,"items":4,"margin":20,"dots":true,"responsive":{
                    0:{items:1},
                    600:{items:2},
                    992:{items:2},
                    1200:{items:4}
                }}},_vm._l((_vm.data),function(item,i){return _c('div',{key:i,staticClass:"projectGrid",style:(_vm.bgImage(item.icon)),on:{"click":function($event){_vm.index = i}}},[_c('div',{staticClass:"projectTitle"},[_c('h3',[_vm._v(_vm._s(item.title))]),_c('span',[_vm._v(_vm._s(item.category))])])])}),0)]:_vm._e(),_c('CoolLightBox',{attrs:{"items":_vm.items,"index":_vm.index},on:{"close":function($event){_vm.index = null}}})],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('h2',[_vm._v("Work Portfolio")]),_c('p',[_vm._v("Creative projects I've contributed to")])])
}]

export { render, staticRenderFns }